import { BrowserRouter, Route, Routes } from "react-router-dom";
import Appointment from "./Component/Appointment/Appointment";
import { createContext, useState } from "react";
import AdminAppointments from "./Admin/Admin Appointments/AdminAppointments";
import AdminAppointmnetView from "./Admin/Admin Appointments/AdminAppointmnetView";
export const UserContext = createContext();

function App() {
  const [value, onChange] = useState(new Date());
  const [apointDate, setApointDate] = useState(false);
  const tileDisabled = ({ date, view }) => {
    return view === "month" && (date.getDay() === 5 || date.getDay() === 6);
  };

  const toCalendarType = (weekStartDay) => {
    return weekStartDay === 0 ? "Arabic" : "ISO 8601";
  };
  return (
    <UserContext.Provider value={{
      value,
      onChange,
      apointDate,
      setApointDate,
      tileDisabled,
      toCalendarType,
    }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Appointment />} />
          <Route path="/admin-appointment" element={<AdminAppointments />} />
          <Route path="appointment/show/:appointmentId" element={<AdminAppointmnetView />} />
        </Routes>
      </BrowserRouter>

    </UserContext.Provider>


  );
}

export default App;
