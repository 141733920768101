import Cookie from "cookie-universal";

// export default function getCookie(name) {
//   // Split cookie string and get all individual name=value pairs in an array
//   var cookieArr = document.cookie.split(";");

//   // Loop through the array elements
//   for (var i = 0; i < cookieArr.length; i++) {
//     var cookiePair = cookieArr[i].split("=");

//     /* Removing whitespace at the beginning of the cookie name
//         and compare it with the given string */
//     if (name == cookiePair[0].trim()) {
//       // Decode the cookie value and return
//       return decodeURIComponent(cookiePair[1]);
//     }
//   }

//   // Return null if not found
//   return null;
// }

export default function getCookie(name) {
  const cookies = Cookie();
  return cookies.get(name);
}
