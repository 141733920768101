import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import { Button } from 'react-bootstrap';
import { BACKEND_BASE_URL } from '../../Component/GlobalVariables';
import getCookie from '../../Component/GetCookie';

const AdminAppointmnetView = () => {
    const { appointmentId } = useParams();
    const [singleInfo, setSingleInfo] = useState([]);
    // Get Single Info
    const renderAllInfo = async () => {
        await axios
            .get(
                `${BACKEND_BASE_URL}/api/v2/rianaire/appointments/show/${appointmentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCookie("ACCESS_TOKEN")}`,
                    },
                }
            )
            .then((res) => {
                setSingleInfo(res.data?.single_info);
            });
    };

    useEffect(() => {
        renderAllInfo();
    }, []);
    return (
        <div className="main__container">
            <div className="body-wrapper">

                <div className="col-md-12 p-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="col-lg-12">
                                <div className="card-header flex_end mb-5">
                                    <Link to="/admin/appointments">
                                        <Button variant="success" size="sm">
                                            View All Appointments &nbsp;
                                        </Button>
                                    </Link>
                                </div>

                                {singleInfo ? (
                                    <>
                                        <h6>
                                            <strong>Name :</strong>&nbsp;{singleInfo?.name}
                                        </h6>
                                        <h6>
                                            <strong>Email :</strong>&nbsp;{singleInfo?.email}
                                        </h6>
                                        <h6>
                                            <strong>Phone :</strong>&nbsp;{singleInfo?.phone_number}
                                        </h6>
                                        <h6>
                                            <strong>Company Name :</strong>&nbsp;{singleInfo?.company_name}
                                        </h6>
                                        <h6>
                                            <strong>Appointment timezone :</strong>&nbsp;{singleInfo?.applicant_time_zone}
                                        </h6>
                                        <h6>
                                            <strong>Appointment Date :</strong>&nbsp;{singleInfo?.appointment_date}
                                        </h6>
                                        <h6>
                                            <strong>Appointment from time :</strong>&nbsp;{singleInfo?.appointment_from_time}
                                        </h6>
                                        <h6>
                                            <strong>Appointment to time :</strong>&nbsp;{singleInfo?.appointment_to_time}
                                        </h6>
                                        <h6>
                                            <strong>Appointment description :</strong>&nbsp;{singleInfo?.appointment_desc}
                                        </h6>
                                        <h6>
                                            <strong>Company country/region :</strong>&nbsp;{singleInfo?.company_country_or_region}
                                        </h6>
                                        <h6>
                                            <strong>Job Title :</strong>&nbsp;{singleInfo?.job_title}
                                        </h6>
                                        <h6>
                                            <strong>Number of Employee :</strong>&nbsp;{singleInfo?.number_of_employee}
                                        </h6>
                                        <h6>
                                            <strong>Designs completed by :</strong>&nbsp;{singleInfo?.designs_completed_by}
                                        </h6>
                                        <h6>
                                            <strong>Website address :</strong>&nbsp;{singleInfo?.website_address}
                                        </h6>
                                    </>
                                ) : (
                                    <LinearProgress />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminAppointmnetView