import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Appointment.css";
import { Col, Form, Row, Button } from "react-bootstrap";
import SheduleButton from "./SheduleButton";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../../App";
import { BsArrowLeftCircle } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { AiTwotoneCalendar } from "react-icons/ai";
import { BACKEND_BASE_URL } from "../GlobalVariables";
import { ToastAlert } from "../ToastAlert";
import { Autocomplete, TextField } from "@mui/material";

const Appointment = () => {
  const name = useRef();
  const email = useRef();
  const companyName = useRef();
  const jobTitle = useRef();
  const phoneNumber = useRef();
  const noOfEmployees = useRef();
  const website = useRef();
  const companyRegion = useRef();
  const description = useRef();
  const timeZone = useRef();
  const {
    value,
    onChange,
    apointDate,
    setApointDate,
    tileDisabled,
    toCalendarType,
  } = useContext(UserContext);

  const [checkboxVal, setCheckboxVal] = useState([]);
  const [radioVal, setRadioVal] = useState();
  const [inputVal, setInputVal] = useState();

  const handleCheckbox = (e) => {
    if (e.target.checked && inputVal) {
      // console.log(inputVal);
      setCheckboxVal([...checkboxVal, e.target.value]);
    } else if (e.target.checked && !inputVal) {
      setCheckboxVal([...checkboxVal, e.target.value]);
    }
    else {
      setCheckboxVal(checkboxVal.filter((id) => id !== e.target.value));
    }
  };

  //   Timezone api
  const [timezone, setTimezone] = useState([]);
  const [optionValue, setOptionVAlue] = useState([]);
  const getTimeZone = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/get-time-zone`).then((res) => {
      setTimezone(res?.data?.time_zone_info);
    });
  };
 
  // form submit to backend
  const handleAppointment = (e) => {

    const formdata = new FormData();
    formdata.append(
      "appointment_from_time",
      localStorage.getItem("appointInfo").split(",")[0]
    );
    formdata.append(
      "appointment_to_time",
      localStorage.getItem("appointInfo").split(',')[1]
    );
    formdata.append(
      "appointment_date",
      localStorage.getItem("appointInfo").split(",")[2] + ", " + localStorage.getItem("appointInfo").split(",")[3]
    );
    formdata.append("applicant_time_zone", localStorage.getItem("timeZone"));
    formdata.append("name", name.current.value);
    formdata.append("email", email.current.value);
    formdata.append("company_name", companyName.current.value);
    formdata.append("job_title", jobTitle.current.value);
    formdata.append("phone_number", phoneNumber.current.value);
    formdata.append("number_of_employee", radioVal);
    if (checkboxVal.length > 0) {
      checkboxVal.forEach((item) => {
        formdata.append("designs_completed_by[]", item);
      });
    }
    formdata.append("website_address", website.current.value);
    formdata.append("company_country_or_region", companyRegion.current.value);
    formdata.append("appointment_desc", description.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/appointment/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            icon: "success",
            title: response.data?.message,
          });
          // e.target.reset();
          localStorage.removeItem("timeZone");
          localStorage.removeItem("appointInfo");
        }
      });
    e.preventDefault();
  };

  //   console.log(optionValue);
  useEffect(() => {
    getTimeZone();
  }, []);

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
  };
  const options2 = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const Top_Date = value.toLocaleString("en-US", options);
  const appointmentDate = value.toLocaleString("en-US", options2);

  // appointment time

  const sheduleTime = [
    {
      time: "10:00 AM",
      id: "1",
      timeValue: "10:00 AM , 10:30 AM",
    },
    {
      time: "10:30 AM ",
      id: "2",
      timeValue: "10:30 AM , 11:00 AM",
    },
    {
      time: "11:00 AM ",
      id: "3",
      timeValue: "11:00 AM , 11:30 AM",
    },
    {
      time: "11:30 AM ",
      id: "4",
      timeValue: "11:30 AM , 12:00 PM",
    },
    {
      time: "12:00 PM",
      id: "5",
      timeValue: "12:00 PM , 12:30 PM",
    },
    {
      time: "12:30 PM ",
      id: "6",
      timeValue: "12:30 PM , 1:00 PM",
    },
    {
      time: "1:00 PM ",
      id: "7",
      timeValue: "1:00 PM , 1:30 PM",
    },
    {
      time: "1:30 PM ",
      id: "8",
      timeValue: "1:30 PM , 2:00 PM",
    },
    {
      time: "2:00 PM ",
      id: "9",
      timeValue: "2:00 PM , 2:30 PM",
    },
    {
      time: "2:30 PM ",
      id: "10",
      timeValue: "2:30 PM , 3:00 PM",
    },
    {
      time: "3:00 PM ",
      id: "11",
      timeValue: "3:00 PM , 3:30 PM",
    },
    {
      time: "3:30 PM ",
      id: "12",
      timeValue: "3:30 PM , 4:00 PM",
    },
    {
      time: "4:00 PM ",
      id: "13",
      timeValue: "4:00 PM , 4:30 PM",
    },
    {
      time: "4:30 PM ",
      id: "14",
      timeValue: "4:30 PM , 5:00 PM",
    },
    {
      time: "5:00 PM ",
      id: "15",
      timeValue: "5:00 PM , 5:30 PM",
    },
    {
      time: "5:30 PM ",
      id: "16",
      timeValue: "5:30 PM , 6:00 PM",
    },
    {
      time: "6:00 PM ",
      id: "17",
      timeValue: "6:00 PM , 6:30 PM",
    },
    {
      time: "6:30 PM ",
      id: "18",
      timeValue: "6:30 PM , 7:00 PM",
    },
  ];

  const [apointmentConfirmId, setApointmentConfirmId] = useState();
  const [apointDateConfirm, setApointDateConfirm] = useState(false);
  const [apointDateConfirmValue, setApointDateConfirmValue] = useState("");
  const getApointmentConfirmId = (id) => {
    setApointmentConfirmId(id);
  };

  const [calenderView, setCalenderView] = useState(true);
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      const updateSize = () => {
        setSize([window.innerWidth, window.innerHeight]);
      };
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [width, height] = useWindowSize();

  // useEffect(() => {
  //   if (apointDate && width < 768) {
  //     setCalenderView(false);
  //   }
  //   else if (width > 768) {
  //     setCalenderView(true);
  //   }
  // }, []);

  const calenderViewReset = () => {
    setApointDate(false);
    setCalenderView(false);
    setApointmentConfirmId("");
  };

  const appointDateReset = () => {
    setApointDateConfirm(false);
  };

  return (
    <>

      <div className="appointment_main mb-5 pb-5">
        <div className="top_sec">
          <div className="container">
            <div className="img_hW d-block mx-auto">
              <img
                className="img-fluid d-block mx-auto"
                src={require("../../Assets/appointment_demo.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="main_content">
          <div className="container">
            <Row className="justify-content-center">
              <Col xxl={8} xl={10}>
                <div className="description text-center my_6">
                  <h1 className="mb-4">Set up a Consultation Session</h1>
                  <p>
                    Unlock the potential of efficient and cost-effective designs
                    with TRODAD. Schedule a personal consultation with one of
                    our experts and discover how our platform can transform your
                    dream project. Say goodbye to time-consuming and costly
                    design/development work, and say hello to improved
                    designs/development and faster growth.
                  </p>
                </div>
              </Col>
              <Col
                xxl={apointDateConfirm ? 8 : 6}
                xl={apointDateConfirm ? 10 : 8}
                md={12}
                sm={10}
              >
                <Row className="apointment_taking_content">
                  {(!apointDate || apointDateConfirm) && (
                    <Col
                      xl={apointDate ? 4 : 5}
                      lg={apointDate ? 12 : 6}
                      md={apointDate ? 12 : 6}
                      className="border_padding"
                    >
                      <div className="content text-md-start text-center">
                        {apointDateConfirm && (
                          <BsArrowLeftCircle
                            className="d-block me-auto  mb-4 cursor"
                            size="30"
                            style={{
                              color: "rgb(6, 148, 130)",
                              backgroundColor: "white",
                            }}
                            onClick={appointDateReset}
                          />
                        )}
                        <div className="logo_wrapper">
                          <img
                            src={require("../../Assets/logo.png")}
                            alt=""
                            className="img-fluid"
                            width={150}
                          />
                        </div>

                        <div className="logo_icon_wrapper">
                          <img
                            src={require("../../Assets/about_us_icon.png")}
                            alt=""
                            className="img-fluid"
                            width={80}
                          />
                        </div>
                        <h5 className="cl-black fw-bold ms-3">
                          Schedule a call
                        </h5>
                        <div className="d-flex flex-wrap align-items-center my-4 justify-content-md-start justify-content-center ms-3">
                          <FaClock className="me-2" />
                          <p className="mb-0"> 30 min </p>
                        </div>

                        {apointDateConfirm ? (
                          <p className="d-flex justify-content-md-start justify-content-center">
                            <span>
                              <AiTwotoneCalendar className="me-2" />
                            </span>
                            <span> {apointDateConfirmValue}</span>
                          </p>
                        ) : (
                          <p className="ms-3">
                            Discover how TRODAD can support your design and
                            development needs. Schedule a complimentary 1:1
                            consultation today.
                          </p>
                        )}
                      </div>
                    </Col>
                  )}

                  {apointDateConfirm ? (
                    <Col xl={7} md={6} className="border_padding details_form">
                      <h4 className="text-md-start text-center">
                        Enter Details
                      </h4>

                      <Form onSubmit={handleAppointment}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Name *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" "
                            required
                            ref={name}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email *</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder=" "
                            required
                            ref={email}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Company Name *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" "
                            required
                            ref={companyName}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Job Title *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" "
                            required
                            ref={jobTitle}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone Number *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" "
                            required
                            ref={phoneNumber}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Number Of Employees *</Form.Label>

                          <Form.Check
                            type="radio"
                            id="emp1"
                            label="1"
                            value="1"
                            name="employee"
                            required
                            onChange={(e) => setRadioVal(e.target.value)}
                          />
                          <Form.Check
                            type="radio"
                            id="emp2"
                            label="2-5"
                            value="2-5"
                            name="employee"
                            onChange={(e) => setRadioVal(e.target.value)}
                          />
                          <Form.Check
                            type="radio"
                            id="emp3"
                            label="6-10"
                            name="employee"
                            value="6-10"
                            onChange={(e) => setRadioVal(e.target.value)}
                          />

                          <Form.Check
                            type="radio"
                            id="emp4"
                            label="11-49"
                            value="11-49"
                            name="employee"
                            onChange={(e) => setRadioVal(e.target.value)}
                          />
                          <Form.Check
                            type="radio"
                            id="emp5"
                            label="50-100"
                            value="50-100"
                            name="employee"
                            onChange={(e) => setRadioVal(e.target.value)}
                          />
                          <Form.Check
                            type="radio"
                            id="emp3"
                            label="Above 100"
                            value="Above 100"
                            name="employee"
                            onChange={(e) => setRadioVal(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>Website Address *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            required
                            ref={website}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>Company's Country Or Region *</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=" "
                            required
                            ref={companyRegion}
                          />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label>
                            How do you currently get your designs completed? *
                          </Form.Label>

                          <Form.Check
                            type="checkbox"
                            id="design1"
                            label="In-House Designers"
                            value="In-House Designers"
                            name="design"
                            onChange={handleCheckbox}
                          />
                          <Form.Check
                            type="checkbox"
                            id="design2"
                            label="Freelancers"
                            value="Freelancers"
                            name="design"
                            onChange={handleCheckbox}
                          />
                          <Form.Check
                            type="checkbox"
                            id="design3"
                            label="Design Agencies"
                            value="Design Agencies"
                            name="design"
                            onChange={handleCheckbox}
                          />

                          <div className="form-check" onChange={handleCheckbox}>
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              id="design4"
                              value={inputVal}
                            />

                            <Form.Control
                              type="text"
                              placeholder="Other"
                              onChange={(e) => setInputVal(e.target.value)}
                            />
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-5"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            EPlease share anything that will help prepare for
                            our meeting. *
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            required
                            ref={description}
                          />
                        </Form.Group>

                        <Button type="submit">Schedule Event</Button>
                      </Form>
                    </Col>
                  ) : (
                    <>
                      {calenderView && (
                        <Col
                          xl={apointDate ? 7 : 7}
                          lg={apointDate ? 8 : 6}
                          md={apointDate ? 7 : 6}
                          className="border_padding "
                        >
                          <div className="content h-100">
                            <h4 className="text-md-start text-center">
                              Select a Date & Time
                            </h4>
                            <Calendar
                              tileDisabled={tileDisabled}
                              calendarType={toCalendarType(0)}
                              showFixedNumberOfWeeks={true}
                              next2Label={""}
                              prev2Label={""}
                              onChange={onChange}
                              value={value}
                              className="border-0"
                              minDate={new Date()}
                              onClickDay={() => setApointDate(true)}
                              minDetail="month"
                            />

                            <Form.Select
                              data-show-content="true"
                              aria-label="Default select example"
                              // ref={timeZone}
                              onChange={(e) => localStorage.setItem("timeZone", e.target.value)}
                            >
                              {/* <option>Open this select menu</option> */}
                              {timezone?.map((data, index) => (
                                <>
                                  <optgroup label={data?.area_name}>
                                    {data?.time_zones?.map((time__zone) => {
                                      const Value = new Date();
                                      const TimeZone = Value.toLocaleString(
                                        "en-US",
                                        {
                                          timeZone: time__zone?.time_zone,
                                        }
                                      );

                                      return (
                                        <option >

                                          {time__zone?.time_zone}

                                        </option>
                                      );
                                    })}
                                  </optgroup>
                                </>
                              ))}
                            </Form.Select>

                            {/* <Form.Group className="mb-4">

                              <Autocomplete
                                onChange={(e, value) => getUserInfo(e, value)}
                                options={timezone?.map((data) => {
                                  return (
                                    data?.time_zones?.map((time__zone) => {
                                      const Value = new Date();
                                      const TimeZone = Value.toLocaleString(
                                        "en-US",
                                        {
                                          timeZone: time__zone?.time_zone,
                                        }
                                      );

                                      return (
                                        <option >
                                          {time__zone?.time_zone}
                                        </option>
                                      );
                                    })
                                  )

                                })}

                                renderInput={(params) => (
                                  <TextField {...params} inputRef={timeZone} />
                                )}

                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      border: "1px solid #86b7fe",
                                    },
                                  },
                                  "& .MuiInputBase-root": {
                                    ".MuiInputBase-root:hover": {
                                      border: "none",
                                    },
                                    padding: "0",
                                  },
                                }}
                              />
                            </Form.Group> */}

                            {/* <Select
                              options={timezone?.map((data, index) => {
                              })}
                            /> */}
                          </div>
                        </Col>
                      )}

                      {apointDate && (
                        <Col xl={5} lg={4} md={5}>
                          <div className="mt-md-5 pt-4">
                            <div className="d-flex align-items-center">
                              {width < 768 && (
                                <BsArrowLeftCircle
                                  className="me-5 cursor"
                                  size="30"
                                  style={{
                                    color: "rgb(6, 148, 130)",
                                    backgroundColor: "white",
                                  }}
                                  onClick={calenderViewReset}
                                />
                              )}

                              <span>{Top_Date}</span>
                            </div>

                            <div className="apointment_confim_shedule my-4">
                              {sheduleTime.map((data) => {
                                return (
                                  <SheduleButton
                                    key={data.id}
                                    id={data.id}
                                    time={data.time}
                                    value={data.timeValue}
                                    apointmentConfirmId={apointmentConfirmId}
                                    getApointmentConfirmId={
                                      getApointmentConfirmId
                                    }
                                    // calender appointment date value sent
                                    appointmentDate={appointmentDate}
                                    // calender appointment date & time value recive
                                    setApointDateConfirmValue={
                                      setApointDateConfirmValue
                                    }
                                    // avail the form
                                    appointForm={setApointDateConfirm}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    </>
  );
};

export default Appointment;
