import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";

const SheduleButton = ({
  time,
  id,
  getApointmentConfirmId,
  apointmentConfirmId,
  value,
  appointmentDate,
  setApointDateConfirmValue,
  appointForm,
}) => {


  const appointConfirmInfo = (value) => {
    let confirmInfo = value + "," + appointmentDate;
    setApointDateConfirmValue(confirmInfo);
    appointForm(true);
    localStorage.setItem("appointInfo", confirmInfo);
    console.log(confirmInfo);
   
  };

  return (
    <div>
      {apointmentConfirmId === id ? (
        <div className=" gap-2 d-flex me-4">
          <Button className="confirm_time border-0">{time}</Button>

          <Button
            className="confirm_btn"
            onClick={() => appointConfirmInfo(value)}
          >
            Confirm
          </Button>
        </div>
      ) : (
        <div className="me-4">
          <Button
            className="w-100 main_btn"
            onClick={() => getApointmentConfirmId(id)}
          >
            {time}
          </Button>
        </div>
      )}
      {/* <Button className='w-100'>
                {time}
            </Button> */}
    </div>
  );
};

export default SheduleButton;
